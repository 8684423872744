.home {
  text-align: center;

  .header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10vh;
    align-items: center;
    justify-content: center;
    color: white;

    .app-logo {
      width: 70%;
      pointer-events: none;

      @media (min-width: 456px) {
        width: 320px;
      }
    }

    .catch-phrase {
      font-size: calc(10px + 1vmin);
      font-style: italic;
      white-space: pre-line;
      height: 10vmin;
    }

    .hashtag {
      display: flex;
      flex-direction: column;
      gap: 4vh;
      align-items: center;
      font-size: calc(4px + 1vmin);

      div {
        display: flex;
        gap: 6vw;
      }
    }
  }

}
